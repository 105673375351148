import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Visual content is awesome! Images can convey emotions, capture our imagination, or simply make a website
aesthetically more appealing. However, many people can't visually perceive our content. Blind and visually
impaired users depend on web developers and content editors to provide alternative text.`}</p>
    <p>{`Unfortunately, a lot of websites have images with misleading alternative text or no alternative text at all.
Imagine you visit a website and instead of all the images you only see blank spaces or fuzzy images.
Not great, huh?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAeFSRXVkaf/EABoQAAICAwAAAAAAAAAAAAAAAAABETECEBL/2gAIAQEAAQUCIx6caV2f/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhgf/aAAgBAwEBPwFOmH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAEAAwAAAAAAAAAAAAAAAAABABEg/9oACAEBAAY/AoU5/8QAGxABAAICAwAAAAAAAAAAAAAAAQAREDEhQVH/2gAIAQEAAT8hm0k7sgLW48OCvU//2gAMAwEAAgADAAAAEF8P/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIaH/2gAIAQMBAT8Qo0RVbByf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EKuP/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITFBUf/aAAgBAQABPxBfPJmC0OUo6XHXu41PmaCZsvDzf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A dark room with neon light letters at the back saying 'Nothing to see here'.",
          "title": "A dark room with neon light letters at the back saying 'Nothing to see here'.",
          "src": "/static/b9ace731ed3ca8206da184a9358c04cb/e5166/pexels-aleksandar-pasaric-nothing-to-see.jpg",
          "srcSet": ["/static/b9ace731ed3ca8206da184a9358c04cb/f93b5/pexels-aleksandar-pasaric-nothing-to-see.jpg 300w", "/static/b9ace731ed3ca8206da184a9358c04cb/b4294/pexels-aleksandar-pasaric-nothing-to-see.jpg 600w", "/static/b9ace731ed3ca8206da184a9358c04cb/e5166/pexels-aleksandar-pasaric-nothing-to-see.jpg 1200w", "/static/b9ace731ed3ca8206da184a9358c04cb/b17f8/pexels-aleksandar-pasaric-nothing-to-see.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Aleksandar Pasaric / pexels.com`}</em></p>
    <p>{`In this article, I'll share some examples of bad alternative text that I regularly encounter in
accessibility audits. But first, let's go over the basics.`}</p>
    <h2>{`The Basics of Accessible Images`}</h2>
    <p>{`Typically, we use the `}<InlineCode mdxType="InlineCode">{`img`}</InlineCode>{` element to display an image on our website.
With the `}<InlineCode mdxType="InlineCode">{`alt`}</InlineCode>{` attribute we can define alternative text for the image.
The `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/#the-img-element"
      }}>{`HTML specification`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`alt — Replacement text for use when images are not available`}</p>
    </blockquote>
    <p>{`This means, that the alt text is displayed on the page if the image can't be loaded, e.g because of network errors.
Of course, it's even more important for accessibility, as screen readers read the text out to their users so they
know what the image means.`}</p>
    <p>{`Other ways to include images on a website are inline `}<InlineCode mdxType="InlineCode">{`svg`}</InlineCode>{` elements or the CSS pseudo
elements `}<InlineCode mdxType="InlineCode">{`:before`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`:after`}</InlineCode>{`. They pose different challenges in
regard to accessibility (see the section “Useful Resources” below).`}</p>
    <h2>{`Write Effective Alternative Text`}</h2>
    <p>{`What all image elements have in common is the question: What kind of alternative text is appropriate for the image?
This depends on the type of image:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Informative images`}</strong>{`: Images that convey relevant information. The text alternative should convey the meaning or
content that is displayed visually.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://css-tricks.com/just-how-long-should-alt-text-be/"
            }}>{`Opinions differ`}</a>{` on
whether the alt text should consist of a single, short sentence or it should describe the image in more detail with
several sentences.`}</li>
          <li parentName="ul">{`In case of complex images (e.g. charts and diagrams), the alternative text should be kept short and
refer to a long description that follows the image.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Decorative images`}</strong>{`: Images that don't add relevant information to the content of a page. This is the case for
images that are added to make the website more visually attractive. Or images that provide information which is
already conveyed using adjacent text. These images should be hidden from assistive technologies, e.g. by setting
an empty alternative text (`}<InlineCode mdxType="InlineCode">{`alt=""`}</InlineCode>{`).`}</li>
      <li parentName="ul"><strong parentName="li">{`Functional images`}</strong>{`: Images used in buttons, links, and other interactive elements. In this case, the alternative
text should convey the purpose of the interactive element and not describe the image itself.`}</li>
    </ul>
    <h2>{`My Personal Top 5 of Bad Alternative Text`}</h2>
    <p>{`When evaluating the accessibility of websites, I regularly encounter the same alt text mistakes over and over.
So here's my personal list of shitty alt text examples:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Title on repeat`}</strong>{`: Article previews (e.g. in a news carousel) usually include a thumbnail image to illustrate the
article. A lot of times, the image's alt text simply repeats the article's title instead of describing the image.
This means redundant information and a waste of time for screen reader users. Please, take the time to describe what the
image actually shows!`}</li>
      <li parentName="ol"><strong parentName="li">{`Did the photographer take a selfie?`}</strong>{` I often encounter photos with alt text that includes the copyright information.
Did the photographer take a selfie and can be seen in the picture? No? Then leave their name out of the alt text!
You can put the copyright in the visible caption of the photo.`}</li>
      <li parentName="ol"><strong parentName="li">{`What does it do?`}</strong>{` Another common mistake is descriptive alt text for functional images. Imagine an icon button that
visually displays a downward arrow. The button allows you to download a document. But the alt text says "arrow down".
Not the best way to convey the button's purpose.`}</li>
      <li parentName="ol"><strong parentName="li">{`I'm not even here`}</strong>{`: Take a `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` element and slap an image on it using
the `}<InlineCode mdxType="InlineCode">{`background-image`}</InlineCode>{` CSS property. `}<span lang="fr">{`Et voilà!`}</span>{` You can see the image. But
there is no information for assistive technologies. Screen reader users won't even notice that the image is there.
Or even worse, they'll arrive at an image link or icon button with no information about their purpose. Thanks for nothing!`}</li>
      <li parentName="ol"><strong parentName="li">{`That's just lazy`}</strong>{`: Sad, but true. I still encounter `}<InlineCode mdxType="InlineCode">{`img`}</InlineCode>{` tags with no
defined `}<InlineCode mdxType="InlineCode">{`alt`}</InlineCode>{` attribute. Just no description at all. As a fallback, screen readers announce the image's
file name instead. Not very helpful, when the image is described as "wdkmm12xpY5.png".`}</li>
    </ol>
    <h2>{`Conclusion`}</h2>
    <p>{`When you include visual content on a page, take a second to answer the following questions: What is the purpose of the
image? Is it purely decorative? If not, define meaningful alternative text that describes the image or the purpose of the
link or button.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/images/"
        }}>{`W3C Images Tutorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/images/decision-tree/"
        }}>{`An alt Decision Tree`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/accessible-svgs/"
        }}>{`Accessible SVGs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adrianroselli.com/2020/10/alternative-text-for-css-generated-content.html"
        }}>{`Alternative Text for CSS Generated Content`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      